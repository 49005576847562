.App{
  padding: 5rem;
}

.container{
  background-color: black;
  width: 100%;
  height: calc(100vh - 10rem);
}

.square{
  padding: 10px;
  display: inline-block;
}

.innerSquare{
  background-color: red;
}